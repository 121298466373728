import React, { useRef, useEffect } from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"

import Glider, { GliderMethods } from "react-glider"

import Layout from "../components/layout"
import { Footer } from "../components/footer"
import SEO from "../components/seo"
import { maxWidth } from "../components/maxwidth"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage } from "gatsby-plugin-image"
import { colors } from "../components/colors"

import "glider-js/glider.min.css"
import "react-glider/glider.defaults.css"

const useStyles = makeStyles(theme => ({
  banner: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    "& .overlay": {
      backgroundColor: "rgba(36,120,153,0.65)",
      mixBlenderMode: "multiply",
    },

    "& .bannerText": {
      marginTop: "90px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },
  catContainer: {
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexWrap: "wrap",

    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },

    "& .categories": {
      width: "100%",
      maxWidth,
      padding: "50px 0px",

      "& .container": {
        width: "100%",
        padding: "0 5%",

        [theme.breakpoints.up("sm")]: {
          padding: "0 15%",
        },

        "& .description": {
          margin: "15px 0",
          color: colors.grayText,
          textAlign: "justify",
        },
      },
    },
  },
  slider: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },
  },
}))

const QuemSomos = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner_quem_somos.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      img01: file(relativePath: { eq: "galeria/img01.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      img02: file(relativePath: { eq: "galeria/img02.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      img03: file(relativePath: { eq: "galeria/img03.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      img04: file(relativePath: { eq: "galeria/img04.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      img05: file(relativePath: { eq: "galeria/img05.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const classes = useStyles()

  const gliderRef = useRef(null)

  useEffect(() => {
    return () => {
      if (gliderRef.current) {
        gliderRef.current.destroy()
      }
    }
  }, [gliderRef.current])

  return (
    <Layout>
      <SEO
        title="O Escritório"
        description="O Escritório é resultado do desejo de oferecer serviços
                  jurídicos assertivos e eficazes. Pensando assim, os advogados
                  Dr. Vanzete e Dr. Gustavo resolveram unir esforços, aliando a
                  larga experiência jurídica com a inovação do mercado."
      />
      <Box
        width={1}
        height="100vh"
        display="flex"
        flexDirection="column"
        style={{ overflowY: "auto" }}
      >
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          height={{ xs: "320px" }}
          mt={{ xs: 0 }}
          className={classes.banner}
          position="relative"
        >
          <GatsbyImage
            image={data.banner.childImageSharp.gatsbyImageData}
            alt="banner home"
          />
          <Box
            width={1}
            height={1}
            position="absolute"
            className="overlay"
          ></Box>
          <Box
            width={1}
            maxWidth={maxWidth}
            position="absolute"
            display="flex"
            flexWrap="wrap"
            height="100%"
            alignItems="center"
            px={{ xs: "10px", sm: "20px" }}
          >
            <Box width={1} p={{ xs: "30px", sm: "50px" }}>
              <Typography variant="h4" className="bannerText">
                O ESCRITÓRIO
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          height={{ xs: "auto", sm: 1 }}
        >
          <Box className={`${classes.catContainer}`}>
            <Box className="categories">
              <Box className="container">
                <Typography variant="body1" className="description">
                  O Escritório é resultado do desejo de oferecer serviços
                  jurídicos assertivos e eficazes. Pensando assim, os advogados
                  Dr. Vanzete e Dr. Gustavo resolveram unir esforços, aliando a
                  larga experiência jurídica com a inovação do mercado.
                </Typography>
                <Typography variant="body1" className="description">
                  Com um novo conceito de gestão e planejamento estratégico dos
                  serviços jurídicos, atuamos de forma ágil, efetiva e
                  personalizada na defesa dos interesses do cliente, para que
                  você possa se dedicar em tempo integral ao que realmente
                  importa em seu negócio.
                </Typography>
                <Typography variant="body1" className="description">
                  Desenvolvemos nossas atividades de modo preventivo e
                  contencioso, orientando os clientes na tomada de decisões e
                  patrocinando as causas necessárias, com uma equipe de
                  advogados capacitados e especializados nas áreas de atuação.
                </Typography>
                <Typography variant="body1" className="description">
                  Nossa missão é desburocratizar e facilitar o acesso a serviços
                  jurídicos de qualidade, de forma dinâmica, transparente e
                  ética, utilizando a tecnologia de forma responsável para
                  oferecer soluções jurídicas consistentes.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width={1}
          maxWidth={maxWidth}
          display="flex"
          mx="auto"
          flexWrap="wrap"
          px={{ xs: "10px", sm: "20px" }}
        >
          <Glider
            ref={gliderRef}
            className={classes.slider}
            hasArrows
            hasDots
            draggable
          >
            <div className="glider-slide">
              <GatsbyImage
                image={data.img01.childImageSharp.gatsbyImageData}
                alt="escritório 01"
                objectFit="contain"
              />
            </div>
            <div className="glider-slide">
              <GatsbyImage
                image={data.img02.childImageSharp.gatsbyImageData}
                alt="escritório 02"
                objectFit="contain"
              />
            </div>
            <div className="glider-slide">
              <GatsbyImage
                image={data.img03.childImageSharp.gatsbyImageData}
                alt="escritório 03"
                objectFit="contain"
              />
            </div>
            <div className="glider-slide">
              <GatsbyImage
                image={data.img04.childImageSharp.gatsbyImageData}
                alt="escritório 04"
                objectFit="contain"
              />
            </div>
            <div className="glider-slide">
              <GatsbyImage
                image={data.img05.childImageSharp.gatsbyImageData}
                alt="escritório 05"
                objectFit="contain"
              />
            </div>
          </Glider>
        </Box>
        <Footer />
      </Box>
    </Layout>
  )
}

export default QuemSomos
